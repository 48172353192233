<template>
  <div class="Visit_Selection_Container">
    <div v-if="$store.getters['session/displaysTuto']">
      <modale-tuto
        :text="$t('manzoni.txt')"
        :logo="logoTuto"
        @closeTutoModale="closeTutoModale"
      />
    </div>
    <Panorama
      v-if="loaded"
      :data="data"
      :imageURL="GetImageURL(this.data.image)"
      :module="'showroom'"
      @PinClicked="GoToVisit($event)"
    />
    <div class="SideMenuContainer">
      <PanelButton
        class="MapButton"
        @Clicked="$store.dispatch('sidePanel/SetMapOpen', true)"
      />
      <SidePanel />
    </div>
    <InfiniteLoader :color="'0,0,0'" v-if="!loaded" class="Lobby__Loader" />
  </div>
</template>

<script>
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import { GetJSON } from '@/utils/filesManager'
import Panorama from '@/components/panorama/Panorama.vue'
import PanelButton from '@/components/panelButton/PanelButton.vue'
import SidePanel from '@/components/sidePanel/SidePanel.vue'
import ModaleTuto from '@/components/modale/ModaleTuto'

export default {
  data () {
    return {
      loaded: false,
      data: { default: [] }
    }
  },
  components: {
    InfiniteLoader,
    Panorama,
    ModaleTuto,
    PanelButton,
    SidePanel
  },
  created () {
    const displaysTuto =
      !this.$store.getters['session/getAlreadySeenTutos'].includes(
        this.$route.name
      ) || this.$store.getters['session/displaysTuto']
    this.$store.dispatch('session/displaysTuto', displaysTuto)
  },
  async mounted () {
    await this.loadData()
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    },
    logoTuto () {
      return this.wording.manzoni.logo.media['en-US'][0].objects.original
    }
  },
  methods: {
    async loadData () {
      try {
        const dat = await GetJSON(
          this.$store.getters['events/getEvents'].default.interfaceContent
            .showroom.shops.media['en-US'][0].objects.original
        )
        this.data = dat
      } catch (e) {
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
    GoToVisit (id) {
      let selected
      this.data.pins.forEach(pin => {
        if (pin.id === id) {
          selected = pin
        }
      })
      if (selected && selected.available) {
        this.$router.push({ name: 'visit', params: { id: id } })
      }
    },
    GetImageURL (name) {
      const wording = this.$store.getters['langs/wording']
      return wording.showroom[name].media['en-US'][0].objects.original
    },
    closeTutoModale () {
      this.$store.dispatch('session/addSeenTuto', this.$route.name)
      this.$store.dispatch('session/displaysTuto', false)
    }
  }
}
</script>

<style scoped lang="scss">
.Visit_Selection_Container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Lobby_ContainerImage {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.Lobby_Image {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.Lobby__Loader {
  margin: auto;
  width: 50px;
  height: 50px;
  align-self: center;
}

.Pins {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  border: 1px white solid;
  border-radius: 100%;
}

.SideMenuContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  z-index: 10;
}

.MapButton {
  pointer-events: auto;
}
</style>
